import {
  Component,
  OnInit
} from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { CoreService } from "src/app/core/core.service";
import { SnotifyService } from "ng-snotify";
import { environment } from "src/environments/environment";
import { CartService } from "src/app/services/cart.service";

@Component({
  selector: "app-books",
  templateUrl: "./books.component.html",
  styleUrls: ["./books.component.scss"],
})
export class BooksComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private coreService: CoreService,
    private snotify: SnotifyService,
    private cartService: CartService
  ) {}

  books: any[] = [];

  ngOnInit(): void {
    this.coreService.getLivros().subscribe({
      next: (res) => {
        this.books = res;
      },
      error: (err) => console.log(err),
    });
  }

  addBookToCart(book) {
    this.router.navigate(['/carrinho'], { queryParams: { 'link-inscricao': book.id } });
  }
}