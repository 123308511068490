import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { SnotifyService } from "ng-snotify";
import { Constants } from "../shared/constants.service";
import { Cart, Item } from "../interfaces/cart";

@Injectable({
  providedIn: "root",
})
export class CartCheckoutService {
  private cartKey = "cart";
  private apiBaseUrl = Constants.apiBaseUrl;
  public cartUpdated: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _snotifyService: SnotifyService,
    private http: HttpClient
  ) {}

  showErrorMessagePopup() {
    const windowWidth = window.innerWidth;
    const screenSizeLimit = 768;
    let position = windowWidth < screenSizeLimit ? "rightTop" : "centerTop";

    this._snotifyService.error("Falha ao tentar realizar a compra.", "", {
      timeout: 4500,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      position: position,
    });
  }

  findCart(id: string) {
    return this.http.get(this.apiBaseUrl + `/get-cart/${id}`);
  }

  deleteCart(id: string) {
    this.http.delete(this.apiBaseUrl + `/remove-cart/${id}`).subscribe();
  }

  addItemInCart(item) {
    const windowWidth = window.innerWidth;
    const screenSizeLimit = 768;
    let position = windowWidth < screenSizeLimit ? "rightTop" : "centerTop";

    let cart: Cart | Partial<Cart> = JSON.parse(
      sessionStorage.getItem(this.cartKey)
    );

    if (!cart) {
      cart = this.setInitialCartValue();
    }

    const differentPaymentType = cart.items.some(
      (itemCart) =>
        itemCart.allow_card !== item.allow_card &&
        itemCart.allow_billet !== item.allow_billet
    );
    if (differentPaymentType) {
      this._snotifyService.error(
        "Produtos com diferentes formas de pagamento. É necessário realizar a compra de cada produto separadamente.",
        "",
        {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: position,
        }
      );
      return;
    }

    //verifica se possuem gateways diferentes ou se é maxipago e centro de resultados diferentes (maxipago exige que seja mesmo centro de resultado)
    const gtDifferent = cart.items.some(
      (itemCart) =>
        itemCart.gt !== item.gt ||
        (item.gt == 1 && itemCart.result_center_id !== item.result_center_id)
    );
    if (gtDifferent) {
      this._snotifyService.error(
        "Compra no carrinho disponível apenas para cursos da mesma região, esvazie o carrinho e tente novamente.",
        "",
        {
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: position,
        }
      );
      return;
    }

    const itemExists = cart.items.some(
      (itemInCart) => itemInCart.id === item.id
    );
    const courseExists = cart.items.some(
      (itemCart) => itemCart.config_tipo_curso_id === item.config_tipo_curso_id
    );
    if (itemExists || courseExists) {
      this._snotifyService.error("Este item já existe no carrinho.", "", {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: position,
      });

      return;
    }

    cart.items.push(item);

    if (item.allow_billet === false) {
      cart.paymentOptions.allow_billet = false;
    }

    if (item.allow_card === false) {
      cart.paymentOptions.allow_card = false;
    }

    sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
    this.cartUpdated.emit();
  }

  setInitialCartValue() {
    const cart = sessionStorage.getItem("cart");
    if (!cart) {
      sessionStorage.setItem(
        this.cartKey,
        '{"paymentOptions":{"allow_card":true,"allow_billet":true},"address":{"status":false,"shippingDestiny":""},"items":[]}'
      );
    }
    return JSON.parse(sessionStorage.getItem(this.cartKey));
  }

  sendCrossData(data) {
    this.http.post(this.apiBaseUrl + "/cross", data).subscribe();
  }
}
