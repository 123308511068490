import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, Title, SafeResourceUrl } from '@angular/platform-browser';

import { Course } from 'src/app/interfaces/course';
import { CoreService } from 'src/app/core/core.service';
import { Testimonial } from 'src/app/interfaces/testimonial';
import { SeoService } from 'src/app/services/seo.service';
import { Constants } from 'src/app/shared/constants.service';
import{MetaService} from 'src/app/services/meta.service'

@Component({
  selector: 'cm-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CourseComponent implements OnInit {
  course: Course;
  slug: string;
  packageCourses: any = [];
  idsCursosRet: number[] = [9, 153, 176, 222, 252, 149, 256, 165, 162, 238];
  idsCursoAHA: number[] = [8, 143, 340, 355];
  testimonials: Testimonial[] = [];
  nextClasses: Course[] = [];
  videoOptions = {
    width: 600,
    height: 315,
  }

  constructor(
    private _coreService: CoreService,
    private _activatedRoute: ActivatedRoute,
    private _domSanitizer: DomSanitizer,
    private _titleService: Title,
    private _seoService: SeoService,
    private _metaService:MetaService
  ) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.slug = params.slug;
      this.course = this._activatedRoute.snapshot.data.courseData;
      this._showApresentationVideo();
      
      if(this.course.media_type == 'video'){
        this.course.media_url = this._cleanUrl(this.course.media_url);
      }

      this._getTestimonialsRelated(this.course.id);
      this._getCoursesPackages();
      this._getNextClasses();

      let setTitle = function(){
        const websiteTitle = Constants.isTerzius ? 'Terzius - ':'Curem - ';
        this._titleService.setTitle(websiteTitle + this.course.name);
      }
      setTimeout(setTitle.bind(this), 100);
    });

    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });
    this._metaService.createCanonicalURL();
  }

  isCourseRetIdValid(courseId: number): boolean {
    return this.idsCursosRet.includes(courseId);
  }

  isCourseAhaIdValid(courseId: number): boolean {
    return this.idsCursoAHA.includes(courseId);
  }

  // ----- PRIVATE METHODS
  private _getCoursesPackages(): void {
    const params = {only_packages: true};
    this._coreService.searchCourses(params).subscribe((response: any) => {
      this.packageCourses = response.data;
    
    })
  }

  private _getTestimonialsRelated(id: number): void {
    let params = {course_id: id};
    this._coreService.getTestimonials(params).subscribe((response: any) => {
      this.testimonials = response;
    })
  }

  private _getNextClasses(): void {
    const params = {
      only_next_classes: true,
      //course_type_ids: this.course.course_type_ids,
      search_text: this.course.fullname
      //city_id: this.course.city_id
    };
    this._coreService.searchCourses(params).subscribe((response: any) => {
      this.nextClasses = response.data;
    })
  }

  private _cleanUrl(url: string) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length >= 11) {
      let url = 'https://www.youtube.com/embed/' + match[2];
      return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return 'error';
    }
  }

  private _showApresentationVideo() {
    // Video modelo
    // this.course.apresentation_url = 'https://www.youtube.com/embed/tgbNymZ7vqY';

    if (window.innerWidth < 600) {
      this.videoOptions.width = window.innerWidth;
    }
    
    if (!!this.course.apresentation_url && this.course.apresentation_url.length > 0) {
      const info = document.getElementById('course-info');
      const description_container = document.getElementById('container');
  
      // Limpando a url
      this.course.apresentation_url = this._cleanUrl(this.course.apresentation_url);
      
      info.classList.add('course-description');
      description_container.classList.remove('cm-container');
    }  
  }

  
}
