import { EventEmitter, Injectable } from "@angular/core";
import { Cart, Item, Address, Combo } from "../interfaces/cart";
import { HttpClient } from "@angular/common/http";
import { Constants } from "../shared/constants.service";
import { ItemShipping } from "../interfaces/item-shipping";
import { concatMap, retry } from "rxjs/operators";
import { SnotifyService } from "ng-snotify";
import { Router } from "@angular/router";
import { RelatedCourse, RelatedCourseCart } from "../interfaces/related-course";
import { Observable, forkJoin, from } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CartService {
  private cartKey = "cart";
  private apiBaseUrl = Constants.apiBaseUrl;

  public cartUpdated: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private http: HttpClient,
    private _snotifyService: SnotifyService,
    private _router: Router
  ) {
    this.setInitialCartValue();
  }



  public addItemToCart(item: Item, compreTambem = false) {

    const windowWidth = window.innerWidth;
    const screenSizeLimit = 768;
    let position = windowWidth < screenSizeLimit ? "rightTop" : "centerTop";

    let cart: Cart | Partial<Cart> = JSON.parse(
      sessionStorage.getItem(this.cartKey)
    );

    if (!cart) {
      cart = this.setInitialCartValue();
    }

    let differentPaymentType = cart.items.some(
      (itemCart) =>
        itemCart.allow_card !== item.allow_card &&
        itemCart.allow_billet !== item.allow_billet &&
        itemCart.allow_pix !== item.allow_pix
    );

    // if (!differentPaymentType) {
    //   cart.items.forEach((cart_item) => {
    //     cart_item.paymentTypes.forEach((value) => {
    //       let havePaymentType = false;

    //       item.paymentTypes.forEach((a) => {
    //         if (a.payment_setting_id == value.payment_setting_id) {
    //           havePaymentType = true;
    //         }
    //       });

    //       if (!havePaymentType) {
    //         differentPaymentType = true;
    //       }
    //     });
    //   });
    // }

    if (differentPaymentType) {
      this._snotifyService.error(
        "Produtos com diferentes formas de pagamento. É necessário realizar a compra de cada produto separadamente.",
        "",
        {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: position,
        }
      );
      return;
    }

    //verifica se possuem gateways diferentes ou se é maxipago e centro de resultados diferentes (maxipago exige que seja mesmo centro de resultado)
    const gtDifferent = cart.items.some(
      (itemCart) =>
        itemCart.gt !== item.gt ||
        (item.gt == 1 && itemCart.result_center_id !== item.result_center_id)
    );
    if (gtDifferent) {
      this._snotifyService.error(
        "Compra no carrinho disponível apenas para cursos da mesma região, esvazie o carrinho e tente novamente.",
        "",
        {
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: position,
        }
      );
      return;
    }

    const itemExists = cart.items.some(
      (itemInCart) => itemInCart.id === item.id
    );

    const courseExists = cart.items.some(
      (itemCart) => itemCart.config_tipo_curso_id === item.config_tipo_curso_id
    );

      if (itemExists || courseExists) {
        this._snotifyService.error("Este item já existe no carrinho.", "", {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: position,
        });

        return;
      }


    if (item.allow_billet === false) {
      cart.paymentOptions.allow_billet = false;
    }

    if (item.allow_card === false) {
      cart.paymentOptions.allow_card = false;
    }

    if (item.allow_pix === false) {
      cart.paymentOptions.allow_pix = false;
    }

    cart.items.push(item);
    sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
    this.cartUpdated.emit();

    if (!compreTambem) {
      this._router.navigate(["/carrinho"]);
    }
  }

  public getTotalItensInCart(): number {
    const cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    if (!cart) {
      return 0;
    }
    return cart.items.length;
  }

  public getCart() {
    const cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    return cart;
  }

  public getPaymentOptionsFromCart() {
    const cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    return cart.paymentOptions;
  }

  public getAddressFromCart() {
    const cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    return cart.address;
  }

  public getItemsFromCart() {
    const cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    return cart.items;
  }

  public getComboFromCart() {
    const cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    return cart.combo;
  }

  public getItemsShippingValue(data: ItemShipping) {
    return this.http
      .post(this.apiBaseUrl + "/calcular-frete", data)
      .pipe(retry(1));
  }

  public updateAddressToCart(address: Address) {
    let cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    cart.address = address;
    sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
  }

  public updateItemsToCart(items: Item[]) {
    let cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    cart.items = items;
    sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
  }

  public updateFullPaymentOptionToCart(isFull: boolean) {
    let cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    cart.paymentOptions.full_payment = isFull;
    sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
  }

  public deleteItemsFromCart(id: number) {
    let cart: Cart = this.getCart();

    cart.items = cart.items.filter((item) => item.id !== id);

    for (let i in cart.combo) {
      let qtd = 0;
      for (let j in cart.items) {
        if (cart.combo[i].token == cart.items[j].token) {
          qtd++;
        }
      }

      if (cart.combo[i].qtd != qtd) {
        cart.combo.splice(parseInt(i), 1);
      }
    }

    if (cart.combo.length === 0) {
      cart.paymentOptions.allow_billet = true;
      cart.paymentOptions.allow_card = true;
    }

    cart.items.forEach((item) => {
      if (!item.allow_billet && item.allow_card) {
        cart.paymentOptions.allow_billet = false;
        cart.paymentOptions.allow_card = true;
      } else if (item.allow_billet && !item.allow_card) {
        cart.paymentOptions.allow_billet = true;
        cart.paymentOptions.allow_card = false;
      }
    });

    if (cart.items.length === 0 && cart.blackFriday) {
      delete cart.blackFriday;
    } else if (cart.items.length === 1 && cart.blackFriday) {
      delete cart.blackFriday.discount_blackfriday;
    } else if (cart.items.length === 2 && cart.blackFriday) {
      delete cart.blackFriday.discount_blackfriday;
    }

    sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
    this.cartUpdated.emit();

    return cart.items;
  }

  public deleteAllItemsFromCart() {
    sessionStorage.removeItem("cart");
    this.setInitialCartValue();
    this.cartUpdated.emit();
  }

  public setInitialCartValue() {
    const cart = sessionStorage.getItem("cart");
    if (!cart) {
      sessionStorage.setItem(
        this.cartKey,
        '{"combo":[],"paymentOptions":{"allow_card":true,"allow_billet":true, "allow_pix":true, "full_payment": true},"address":{"status":false,"shippingDestiny":""},"items":[], "blackFriday": null}'
      );
    }
    return JSON.parse(sessionStorage.getItem(this.cartKey));
  }

  public setNewCart(cartItems: Item[]) {
    return this.http.post(this.apiBaseUrl + "/add-cart", cartItems);
  }

  public setComboCartValue(combo: Combo) {
    let cart: Cart | Partial<Cart> = JSON.parse(
      sessionStorage.getItem(this.cartKey)
    );

    let test = false;
    for (let i in cart.combo) {
      if (cart.combo[i].token == combo.token) {
        test = true;
      }
    }

    if (!test) {
      cart.combo.push(combo);
      sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
    }
  }

  public getItensCombo(token: string) {
    return this.http.get(`${this.apiBaseUrl}/combo/${token}`).pipe(retry(1));
  }

  public getItenLinkInscricao(id: string) {
    return this.http
      .get(`${this.apiBaseUrl}/link-inscricao/${id}`)
      .pipe(retry(1));
  }

  public verificaPagamento(idVenda) {
    return this.http.get(this.apiBaseUrl + `/get-status-inscricao/${idVenda}`);
  }

  getPeriodoBlackFriday(): Promise<any> {
    return this.http.get<any>(`${this.apiBaseUrl}/periodo-black-friday`).toPromise();
  }
}
