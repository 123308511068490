import { Component, OnInit } from '@angular/core';
import { CoreService } from '../core.service';
import { Phone } from 'src/app/interfaces/phone';
import { Constants } from 'src/app/shared/constants.service';

@Component({
  selector: 'cm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  phones: any;
  courseGroups: any;
  currentTypeOpen: number = -1;
  logo:string = "CUREM";
  about:string = "A CUREM";
  isTerzius:boolean;
  constructor(private _coreService: CoreService) { }

  ngOnInit() {

    if(Constants.isTerzius) {
      this.logo = "TERZIUS";
      this.about = "A TERZIUS";
    }

    this.isTerzius = Constants.isTerzius;
    this._getPhoneList();
    this._getCourseGroupList();
  }

  // ----- PUBLIC METHODS
  cleanPhoneNumber(phone: any): string {
    return phone.replace(/\D/g,'');
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  toogleTypes(id: number): void {
    this.currentTypeOpen == id ? this.currentTypeOpen = -1 : this.currentTypeOpen = id;
  }

  // ----- PRIVATE METHODS
  private _getPhoneList(): void {
    this._coreService.getPhones().subscribe((response: any) => {
      this.phones = response;
    })
  }

  private _getCourseGroupList(): void {
    this._coreService.getCourseGroups().subscribe((response: any) => {
      this.courseGroups = response;
    })
  }

}
