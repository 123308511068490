import { Component, Renderer2, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Constants } from 'src/app/shared/constants.service';
import { CoreService } from './core/core.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private _router: Router,
    private _coreService: CoreService,
    private _metaService: Meta,
    private _activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) document,
    r: Renderer2,
    private _titleService: Title) {
      document.querySelector("link[rel*='icon']").href = "favicon.ico";
    }

  ngOnInit() {

    this._router.events
      .pipe(filter(event => {
        if(event instanceof NavigationEnd){
          return true;
        }
        return false;
      }))
      .pipe(map(() => this._activatedRoute))
      .pipe(map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(switchMap(route => route.data))
      .subscribe(event => this._titleService.setTitle(event.title));

    //-------- LOAD TRACKING SCRIPTS

    //GTag Manager
    this.addScriptCode("(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KMVHPLRQ')");
    this.addScriptCode("(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-T6V2J2FQ')");
    
    
    this.addNoScript('<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KMVHPLRQ" height="0" width="0" style="display:none;visibility:hidden"></iframe>');
    this.addNoScript('<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T6V2J2FQ" height="0" width="0" style="display:none;visibility:hidden"></iframe>');

    // //GTag
    // this.addScript('https://www.googletagmanager.com/gtag/js?id=UA-1887643-18', () => {
    //   this.addScriptCode("window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-1887643-18'); ");
    // })

    // //Facebook
    // this.addScriptCode("!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '298733450753728');fbq('track', 'PageView')");
    // this.addNoScript('<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=298733450753728&ev=PageView&noscript=1" />');

    this.addScript('https://kit.fontawesome.com/a4d42462fe.js','');

  }

  public addNoScript(content) {
    let node = document.createElement('noscript');
    node.innerHTML = content;
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  public addScriptHead(url) {
    let node = document.createElement('script');
    node.type = 'text/javascript';
    node.async = true;
    node.src = url;

    document.getElementsByTagName('head')[0].appendChild(node);
  }
  public addScriptBody(url) {
    let node = document.createElement('script');
    node.type = 'text/javascript';
    node.async = true;
    node.src = url;

    document.getElementsByTagName('body')[0].appendChild(node);
  }


  public addScriptCode(code) {
    let node = document.createElement('script');
    node.type = 'text/javascript';
    node.innerHTML = code;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  public addScript(url, callback) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
    if(callback != ''){
      callback();
    }

  }

}
