// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  apiUrl: "https://api.curem.com.br/api/site",
  siteUrl: "https://curem.com.br",
  // apiUrl: "http://localhost:9001/api/site",
  //siteUrl: "http://localhost:4200",
  //apiUrl: "https://apibeta.grupohne.com.br/api/site",
  //siteUrl: "https://curembeta.curem.com.br",  
  //apiUrl: "http://localhost:9001/api/site",
  //siteUrl: "http://localhost:4200",
  // apiUrl: "https://apibeta.grupohne.com.br/api/site",
  // siteUrl: "https://curembeta.curem.com.br",
  amazon_s3: 'https://gruponhe.s3.us-east-2.amazonaws.com/site'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
