import { Component, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/app/shared/constants.service';
import {PermissaoCookieComponent} from 'src/app/shared/permissao-cookie/permissao-cookie.component';

@Component({
  selector: 'cm-politica-cookies',
  templateUrl: './politica-cookies.component.html',
  styleUrls: ['./politica-cookies.component.scss']
})
export class PoliticaCookiesComponent implements OnInit {
  @ViewChild ('permissao-cookie') permissaoCookie

  banner_url_terzius: any = "https://hne-s3.s3.us-east-2.amazonaws.com/arquivos/banner_padrao_terzius.jpg";
  banner_url_curem: any = "https://hne-s3.s3.us-east-2.amazonaws.com/arquivos/banner_padrao.jpg";
  titulo: any = "Política de Cookies";
  isTerzius: boolean;


  constructor() { }

  ngOnInit() {
    Constants.isTerzius?this.isTerzius = true:false;
  }

}
