import {
  Component,
  OnInit,
  Input
} from "@angular/core";
import { Item, Address } from "src/app/interfaces/cart";
import { CartService } from "src/app/services/cart.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ItemShipping } from "src/app/interfaces/item-shipping";
import { PurchaseResume } from "src/app/interfaces/purchase-resume";
import { CoreService } from "src/app/core/core.service";
import { SnotifyService } from "ng-snotify";
import { RelatedCourse } from "src/app/interfaces/related-course";
import { environment } from "src/environments/environment";
import { CurrencyPipe } from "@angular/common";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cartService: CartService,
    private coreService: CoreService,
    private snotify: SnotifyService,
    private currencyPipe: CurrencyPipe
  ) {}

  @Input() cep: string = "";
  @Input() shippingType: string = "";
  cartItems: Item[] = [];
  address: Address;
  showCepInput: boolean = true;
  isCepLoading = false;
  voucherApplied = false;
  dialog = false;
  dialogValue = false;
  showEbookMessage = false;
  isBlackFriday = false;

  totalDiscountBlackFriday = 0;
  disableVoucherField = false;
  voucherBlackFriday = '';
  voucherValue = 0;
  includesCoursesRet = false;
  itemComVoucher =  {
    id: null,
    discount: null
  };

  relatedCourses: RelatedCourse[] = [];
  purchaseResume: PurchaseResume = {
    subtotal: {
      label: "---",
      value: 0,
    },
    total: {
      label: "---",
      value: 0,
    },
    discountCombo: {
      label: "---",
      value: 0,
    },
    discountVoucher: {
      label: "---",
      value: 0,
    },
    shipping: {
      label: "---",
      value: 0,
    },
  };

  tipoPagamento: any[] = [
    {
      label: "",
      value: 'entrada',
    },
    {
      label: "",
      value: 'total',
    }
  ];

  tipoPagamentoSelecionado: string = 'total';
  isTipoEntradaTurma: boolean = false;

  enableContinueButton: boolean = false;
  loadingButton: boolean = false;
  shippingError: boolean = false;
  payment_category_cartao = 1;

  async ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      if (params["combo"]) {
        let response = await this.cartService
          .getItensCombo(params["combo"])
          .toPromise();
        this.setComboCart(response);
      }

      if (params["link-inscricao"]) {
        let response: any = await this.cartService
          .getItenLinkInscricao(params["link-inscricao"])
          .toPromise();

        if (response.error) {
          this.snotify.error(response.error, {
            timeout: 6000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: "centerTop",
          });
          return;
        }

        this.setLinkInscricao(response);

        if (response.isTipoEntrada) {
          this.dialogValue = true;
        }
      }

      await this.getBlackFridayPeriod();

      this.cartService.cartUpdated.subscribe(() => {
        this.getItemsFromCart();
        this.calculateSubTotalValue();
        this.calculateDiscountTotalValue();
        this.calculatePurchaseResumeTotalValue();
        this.verifyPaymentTypes();
        this.orderPaymentTypes();
      });

      this.getAddressFromCart();
      this.getItemsFromCart();
      this.calculateShippingForNewItem();
      this.setCepInputValueWithCartItemCep();
      this.calculateShippingTotalValue();
      this.calculateSubTotalValue();
      this.calculateDiscountTotalValue();
      this.calculatePurchaseResumeTotalValue();
      this.allowContinueButton();
      this.getRelatedCourses();
      this.verifyPaymentTypes();
      this.orderPaymentTypes();
      this.setTipoPagamentoValues();
    });
  }

  async getBlackFridayPeriod() {
    try {
      const res = await this.cartService.getPeriodoBlackFriday();
      this.isBlackFriday = res.is_black_friday;
    } catch (err) {
      console.log(err);
    }
  }

  isItemsEmpty(): boolean {
    return this.cartItems.length === 0;
  }

  setComboCart(data: object) {
    this.cartService.setComboCartValue({
      token: data["token"],
      discount: data["desconto"],
      qtd: data["itens"].length,
    });
    for (let i in data["itens"]) {
      let it = data["itens"][i];

      const item: Item = {
        id: it.id,
        image: it.image_url,
        black_friday: false,
        title: it.title,
        subtitle: it.subtitle,
        date: [it.date],
        place: it.place,
        gt: it.gt,
        config_tipo_curso_id: it.config_tipo_curso_id,
        result_center_id: it.result_center_id,
        value: it.value,
        allow_card: it.allow_card,
        allow_billet: it.allow_billet,
        token: it.token,
      };

      this.cartService.addItemToCart(item);
    }
  }

  setLinkInscricao(it) {
    const item: Item = {
      id: it.id,
      image: it.image_url,
      black_friday: it.desconto_blackfriday ? true : false,
      desconto_blackfriday: it.desconto_blackfriday,
      title: it.title,
      subtitle: it.subtitle,
      date: [it.date],
      place: it.place,
      gt: it.gt,
      config_tipo_curso_id: it.config_tipo_curso_id,
      result_center_id: it.result_center_id,
      value: it.value,
      allow_card: it.allow_card,
      allow_billet: it.allow_billet,
      allow_pix: it.allow_pix,
      isPromotion: it.isPromotion,
      descontoPromocao: it.descontoPromocao,
      turmaExclusiva: it.turma_exclusiva,
      paymentTypes: it.class_payment_settings,
      isTipoEntrada: it.isTipoEntrada,
      valorEntrada: it.valorEntrada,
    };

    this.cartService.addItemToCart(item);
  }

  calculateShippingForAllItems() {
    if (this.cep.length < 9) {
      this.snotify.error("O cep informado está incompleto.", {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: "centerTop",
      });
      return;
    }

    this.isCepLoading = true;
    const listItensIds = this.cartItems.map((value) => value.id);

    if (!this.cep) {
      this.isCepLoading = false;
    } else {
      const data: ItemShipping = {
        cep: this.cep,
        courses_ids: listItensIds,
      };

      this.cartService
        .getItemsShippingValue(data)
        .subscribe((response: any) => {
          if (response.erro) {
            this.snotify.error(response.erro, {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              position: "centerTop",
            });
            this.isCepLoading = false;
            this.shippingError = true;

            this.cleanAllCartItemsShippingData();
            this.cartService.updateItemsToCart(this.cartItems);

            return;
          }

          this.resetShippingTypeSelectedForAllCartItens();
          this.setShippingValueForAllCartItens(response["shipping"]);
          this.cartService.updateItemsToCart(this.cartItems);

          if (typeof response["address"]['uf'] === 'string') {
            response["address"]['uf'] = {
                "label": response["address"]['uf'],
                "value": response["address"]['uf']
            };
          }

          this.cartService.updateAddressToCart(response["address"]);
          this.setDefaultShippingTotalValueData();
          this.calculateDiscountTotalValue();
          this.calculatePurchaseResumeTotalValue();
          this.allowContinueButton();

          this.shippingError = false;
          this.isCepLoading = false;
        });
    }
  }

  cleanAllCartItemsShippingData() {
    this.cartItems.map((value) => {
      value.shippingTypes = [];
      value.shippingTypeSelected = "";
      value.shippingValue = 0;
    });
  }

  getAddressFromCart() {
    this.address = this.cartService.getAddressFromCart();
  }

  async getItemsFromCart() {
    this.cartItems = this.cartService.getItemsFromCart();

    //Desabilitar com o fim da black friday

    this.cartItems.forEach((item) => {
      if (item.turmaExclusiva && item.turmaExclusiva == 's') {
        this.isBlackFriday = false;
      }
    });

    if (this.isBlackFriday) {
      this.calcTotalDiscountBlackFriday(this.cartItems);
    }
  }

  calcTotalDiscountBlackFriday(cartItems: any[]) {
    const totalItemsInCart = cartItems.length;
    const idsRET = [153, 176, 222, 252, 149, 256, 165, 162, 238];
    const carItemsCourseIds = this.cartItems.map(item => item.config_tipo_curso_id);
    const includesCoursesRet = idsRET.some(id => carItemsCourseIds.includes(id));

    this.includesCoursesRet = includesCoursesRet;
    const itemsFromSession = sessionStorage.getItem('cart');
    const cart = JSON.parse(itemsFromSession);

    const discounts = {
      0: 0,
      1: 0,
      2: includesCoursesRet ? 400 : 0,
      3: includesCoursesRet ? 800 : 0,
      4: includesCoursesRet ? 1200 : 0
    };

    if (cartItems.length === 1 && includesCoursesRet) {
      this.disableVoucherField = false;
      this.cartItems = this.cartService.getItemsFromCart();
    }

    if (cartItems.length === 1 && !includesCoursesRet) {
      let newCartItems = [];

      if (cart.blackFriday) {
        cart.discount_blackfriday = 0;
      }

      cartItems.forEach((item) => {
        if (!item.voucherApplied || item.voucherApplied === false) {
          this.disableVoucherField = false;

          if(cart.blackFriday && cart.blackFriday.voucher_value) {
            cart.blackFriday.voucher_value = null;
          }

          if(cart.blackFriday && cart.blackFriday.cupom) {
            cart.blackFriday.cupom = null;
          }

        }

        if (item.discount > 0) {
          item.discount = null;
        }

        if (this.itemComVoucher.id  && item.id === this.itemComVoucher.id ) {
          item.discount = this.itemComVoucher.discount;
        }

        newCartItems.push(item);
      });

      cart.items = newCartItems;
      sessionStorage.setItem('cart', JSON.stringify(cart));

      this.cartItems = this.cartService.getItemsFromCart();
      this.totalDiscountBlackFriday = 0;

    }

    if (cartItems.length > 1 && includesCoursesRet) {
      let voucherApplied = false;

      cartItems.forEach((item: any) => {
        if (item.valorCupomCopia > 0 && !voucherApplied) {
          this.disableVoucherField = true;
          this.itemComVoucher.id = item.id;
          this.itemComVoucher.discount = item.valorCupomCopia;
        }

        if (item.discount > 0 && !voucherApplied) {
          this.disableVoucherField = true;
          this.itemComVoucher.id = item.id;
          this.itemComVoucher.discount = item.discount;
          item.valorCupomCopia = item.discount;

          voucherApplied = true;
        }

      });

    }

    if (cartItems.length > 1 && !includesCoursesRet) {

      cartItems.forEach((item) => {
        if (item.discount > 0) {
          this.disableVoucherField = true;
          this.itemComVoucher.id = item.id;
          this.itemComVoucher.discount = item.discount;
        }
      });
    }

    if (this.totalDiscountBlackFriday > 0 && includesCoursesRet) {
      const valorParaDesconto = discounts[totalItemsInCart] - this.totalDiscountBlackFriday;
      this.totalDiscountBlackFriday = this.totalDiscountBlackFriday + valorParaDesconto;
    }

    if (cart.blackFriday && !includesCoursesRet) {
      this.voucherBlackFriday = cart.blackFriday.cupom ? cart.blackFriday.cupom : '';
    }

    if (cart.blackFriday && this.totalDiscountBlackFriday === 0 && includesCoursesRet && totalItemsInCart > 1) {
      const valueVoucher = cart.blackFriday.voucher_value ? cart.blackFriday.voucher_value : '';
      this.totalDiscountBlackFriday = discounts[totalItemsInCart];
      cartItems.forEach((item) => item.discount = 0);
    }

    if (!cart.blackFriday && totalItemsInCart > 1 && includesCoursesRet) {
      this.totalDiscountBlackFriday = discounts[totalItemsInCart];
    }
  }

  deleteItemFromCart(id: number) {
    const cartUpdated = this.cartService.deleteItemsFromCart(id);
    this.cartItems = cartUpdated;

    this.calculateSubTotalValue();
    this.calculateShippingTotalValue();
    this.calculatePurchaseResumeTotalValue();
    this.calculateDiscountTotalValue();
    this.allowContinueButton();
    this.verifyPaymentTypes();
    this.orderPaymentTypes();

    if (this.cartItems.length == 0) this.setDefaultShippingTotalValueData();
  }

  setShippingValueForAllCartItens(data: Object) {
    this.cartItems.map((value) => {
      if (!value.shippingTypes) value.shippingTypes = [];

      value.shippingDestiny = this.cep;
      value.shippingTypes = [];

      for (let key in data[value.id]) {
        const shippingInfo = {
          type: key,

          description: {
            ...data[value.id][key],
            shipping_time: data[value.id][key].shipping_time,
            shipping_value_label: this.formatValueLabel(
              data[value.id][key].shipping_value
            ),
          },
        };

        value.shippingTypes.push(shippingInfo);
      }
    });
  }

  calculateShippingForNewItem() {
    if (this.cartItems.length) {
      if (
        this.address.shippingDestiny &&
        !this.cartItems[this.cartItems.length - 1].shippingDestiny
      ) {
        const shippingDestiny = this.address.shippingDestiny;
        if (shippingDestiny) {
          const newItems = this.cartItems.filter(
            (value) => !value.shippingDestiny
          );
          const data: ItemShipping = {
            cep: shippingDestiny,
            courses_ids: newItems.map((item) => item.id),
          };

          this.cartService
            .getItemsShippingValue(data)
            .subscribe((response: any) => {
              newItems.forEach((newItem) => {
                this.setShippingValueForNewCartItem(
                  newItem.id,
                  response["shipping"]
                );
              });
              this.cartService.updateItemsToCart(this.cartItems);
              this.allowContinueButton();
            });
        }
      }
    }
  }

  setShippingValueForNewCartItem(id: number, data: Object) {
    this.cartItems.map((value) => {
      if (value.id == id && !value.shippingDestiny) {
        value.shippingDestiny = this.address.shippingDestiny;
        if (!value.shippingTypes) value.shippingTypes = [];

        for (let key in data[value.id]) {
          const shippingInfo = {
            type: key,
            description: {
              ...data[value.id][key],
              shipping_value_label: this.formatValueLabel(
                data[value.id][key].shipping_value
              ),
            },
          };

          value.shippingTypes.push(shippingInfo);
        }
      }
    });
  }

  formatValueLabel(data: number): string {
    return data <= 0
      ? "Grátis"
      : Number(data).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
  }

  setDataShippingSelected(
    id: number,
    typeSelected: string,
    shipping_time: number,
    shipping_value: number
  ) {

    typeSelected === 'ebook' ? this.openPopup() : this.closePopup();

    this.cartItems.forEach((value) => {
      if (id == value.id) {
        value.shippingTypeSelected = typeSelected;
        value.shippingTime = shipping_time;
        value.shippingValue = shipping_value;
      }
    });

    this.cartService.updateItemsToCart(this.cartItems);

    this.calculateShippingTotalValue();
    this.calculateDiscountTotalValue();
    this.calculatePurchaseResumeTotalValue();
    this.allowContinueButton();
  }

  openPopup() {
    document.getElementById('ebookPopup').style.display = "block";
  }

  closePopup() {
    document.getElementById('ebookPopup').style.display = "none";
  }

  calculateShippingTotalValue() {
    let totalValue = 0;
    let hasShippingTypeNotSelected = false;

    this.cartItems.forEach((value) => {
      const { shippingTypeSelected, shippingTypes } = value;

      if (shippingTypes) {
        if (shippingTypes.length > 0) {
          if (shippingTypeSelected) {
            shippingTypes.map((shippingType) => {
              if (shippingType.type == shippingTypeSelected)
                totalValue += shippingType.description.shipping_value;
            });
          } else {
            hasShippingTypeNotSelected = true;
          }
        } else {
          if (!value.shippingDestiny) hasShippingTypeNotSelected = true;
        }
      } else {
        hasShippingTypeNotSelected = true;
      }
    });

    if (this.cartItems.length) {
      if (!this.address.shippingDestiny) {
        this.setDefaultShippingTotalValueData();
      }

      if (!hasShippingTypeNotSelected) {
        this.purchaseResume.shipping.value = totalValue;
        this.purchaseResume.shipping.label = this.formatValueLabel(totalValue);
      }
    }
  }

  checkShippingTypeSelected(
    shippingMethodTypeSelected: string,
    shippingMethodType: string
  ) {
    return shippingMethodType == shippingMethodTypeSelected;
  }

  setCepInputValueWithCartItemCep() {
    if (this.cartItems.length) {
      this.cep = this.address.shippingDestiny;
    }
  }

  setDefaultShippingTotalValueData() {
    this.purchaseResume.shipping.value = 0;
    this.purchaseResume.shipping.label = "---";
  }

  setDefaultSubTotalValueData() {
    this.purchaseResume.subtotal.value = 0;
    this.purchaseResume.subtotal.label = "---";
  }

  calculateSubTotalValue() {
    const paymentOptions = this.cartService.getPaymentOptionsFromCart();

    const total = this.cartItems.reduce(
      (previous, current) => previous + (!paymentOptions.full_payment ? current.valorEntrada : current.value),
      0
    );

    if (this.cartItems.length > 0) {
      this.purchaseResume.subtotal.label = this.formatValueLabel(total);
      this.purchaseResume.subtotal.value = total;
    } else {
      this.setDefaultSubTotalValueData();
    }
  }

  calculatePurchaseResumeTotalValue() {
    const itemsFormSession = sessionStorage.getItem('cart');
    const cart: any = JSON.parse(itemsFormSession);

    if (this.isBlackFriday && this.totalDiscountBlackFriday > 0) {

      cart.blackFriday = {
        discount_blackfriday: this.totalDiscountBlackFriday,
        voucher_value: this.voucherValue,
        cupom: this.voucherBlackFriday
      };

      sessionStorage.removeItem('cart');
      sessionStorage.setItem('cart', JSON.stringify(cart));
    }

    const paymentOptions = this.cartService.getPaymentOptionsFromCart();

    let total = 0;

    if (this.isBlackFriday && cart.items.length > 1 && this.includesCoursesRet) {
      total =
        this.purchaseResume.subtotal.value +
        (paymentOptions.full_payment ? this.purchaseResume.shipping.value : 0) -
        this.purchaseResume.discountCombo.value -
        this.totalDiscountBlackFriday;
    } else {
      total =
        this.purchaseResume.subtotal.value +
        (paymentOptions.full_payment ? this.purchaseResume.shipping.value : 0) -
        this.purchaseResume.discountCombo.value -
        this.totalDiscountBlackFriday -
        this.purchaseResume.discountVoucher.value;
    }

    if (this.cartItems.length > 0) {
      this.purchaseResume.total.label = this.formatValueLabel(total);
      this.purchaseResume.total.value = total;
    } else {
      this.purchaseResume.total.value = 0;
      this.purchaseResume.total.label = "---";
    }
  }

  handleZipCode(event: any) {
    let input = event.target;

    if (input.value.length == 9 && !input.value.includes("-"))
      input.value = input.value.split("").slice(0, -1);

    input.value = this.zipCodeMask(input.value);

    this.cep = input.value;
  }

  zipCodeMask(value: string) {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{5})(\d)/, "$1-$2");
    return value;
  }

  allowContinueButton() {

    if(this.cartItems.length > 0 && this.cep) {

      let itemWithoutShippingTypeSelected = this.cartItems.find((value) => {
        if (!!value.shippingTypes)
          return value.shippingTypes.length > 0 && !value.shippingTypeSelected;
      });

      if (!itemWithoutShippingTypeSelected && !this.shippingError) {
        this.enableContinueButton = true;
        return;
      }

      if (
        itemWithoutShippingTypeSelected &&
        itemWithoutShippingTypeSelected.shippingTypes.length > 0 &&
        !this.shippingError
      ) {
        this.enableContinueButton = false;
      }

      if (
        itemWithoutShippingTypeSelected.shippingTypes &&
        itemWithoutShippingTypeSelected.shippingTypes.length == 0 &&
        !this.shippingError
      ) {
        this.enableContinueButton = true;
      } else {
        this.enableContinueButton = false;
      }
    }
  }

  checkCupom(cupom: string, courseId: number) {
    let ids = [];
    let url = '/coupom';

    if (this.isBlackFriday &&  this.cartItems.length > 1 && this.includesCoursesRet) {
      url = '/coupom-black-friday';
    }

    ids.push(courseId);

    const data = {
      coupom: cupom,
      "courses_ids[]": ids,
      payment_method: "",
      installment: 1,
      url: url
    };

    this.coreService.checkCoupom(data).subscribe({
      next: (res: any) => {
        if (res.status === "error") {
          this.snotify.error(res.message, {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: "centerTop",
          });

          return;
        }

        if (!res.total_desconto) {
          const data = res[0];
          this.changePriceOfItem(courseId, data.id, data.value);
          this.snotify.success("Voucher adicionado com sucesso!", {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: "centerTop",
          });

          if (this.isBlackFriday) {
            this.disableVoucherField = true;

            res.forEach((item) => {
              this.voucherBlackFriday = item.id;
            });
          }

          this.calculateSubTotalValue();
          this.calculateDiscountTotalValue();
          this.calculatePurchaseResumeTotalValue();
        } else {
          this.totalDiscountBlackFriday += res.total_desconto;
          this.voucherBlackFriday = res.cupom;
          this.voucherValue = res.total_desconto;

          this.calcTotalDiscountBlackFriday(this.cartItems);

          if (this.totalDiscountBlackFriday === 0 && res.total_desconto > 400) {
            this.totalDiscountBlackFriday = res.total_desconto;
          }

          this.disableVoucherField = true;
          this.snotify.success("Voucher adicionado com sucesso!", {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: "centerTop",
          });

          this.calculateSubTotalValue();
          this.calculateDiscountTotalValue();
          this.calculatePurchaseResumeTotalValue();
        }

      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  changePriceOfItem(id: number, coupom_id: number, discount: number) {
    const index = this.cartItems.findIndex((item) => item.id === id);

    if (index === -1) {
      throw new Error(`Item com ID ${id} não encontrado no carrinho.`);
    }

    const updatedItem: Item = {
      ...this.cartItems[index],
      discounted_price: this.cartItems[index].value - discount,
      discount,
      voucherApplied: true,
      coupom_id,
    };

    this.cartItems = [
      ...this.cartItems.slice(0, index),
      updatedItem,
      ...this.cartItems.slice(index + 1),
    ];

    this.cartService.updateItemsToCart(this.cartItems);
  }

  getRelatedCourses() {
    if (this.cartItems.length == 0) {
      return;
    }

    const idMainCourse = this.cartItems[0].id;
    this.coreService.getRelatedCourses(idMainCourse).subscribe({
      next: (res) => {
        this.relatedCourses = res;
      },
      error: (err) => console.log(err),
    });
  }

  formatStringWithEllipsis(text: string) {
    if (text.length <= 34) {
      return text;
    } else {
      return text.slice(0, 34) + "...";
    }
  }

  resetShippingTypeSelectedForAllCartItens() {
    this.cartItems.map((value) => {
      value.shippingTypeSelected = "";
    });
  }

  calculateDiscountTotalValue() {
    let cart = this.cartService.getCart();

    let discountCombo = 0;
    let discountVoucher = 0;

    discountVoucher = this.cartItems.reduce((previous, current) => {
      if (current.discount) {
        return previous + current.discount;
      }
      return previous + 0;
    }, 0);

    if (this.isBlackFriday) {
      discountVoucher = this.cartItems.reduce((previous, current) => {
        if (current.discount) {
          return previous + current.discount;
        }
        return previous + 0;
      }, 0);
    }

    if (discountVoucher) {

      this.purchaseResume.discountVoucher.label = this.formatValueLabel(discountVoucher);
      this.purchaseResume.discountVoucher.value = discountVoucher;

    } else {

      this.purchaseResume.discountVoucher.value = 0;
      this.purchaseResume.discountVoucher.label = "---";
    }

    for (let i in cart["combo"]) {
      discountCombo += cart["combo"][i]["discount"];
    }

    if (discountCombo) {

      this.purchaseResume.discountCombo.label = this.formatValueLabel(discountCombo);
      this.purchaseResume.discountCombo.value = discountCombo;

    } else {

      this.purchaseResume.discountCombo.value = 0;
      this.purchaseResume.discountCombo.label = "---";
    }

  }

  pegaValorBlackFriday(value, desconto_blackfriday){

    return value / (1 - desconto_blackfriday / 100);
  }

  pegaValorPromocao(value, desconto, tipoPromocao){

    return tipoPromocao == "porc" ? value / (1 - desconto / 100) : value + desconto;
  }

  getDate(item: any) {

    if(!item.date || item.date.length == 0 || !item.date[0]){
      return false;
    }

    return typeof item.date == "string" ? item.date : item.date[0];
  }

  closeDialog(): void {
    this.dialog = false;
  }

  closeValueDialog(): void {
    this.dialogValue = false;
  }

  adicionarCarrinho() {
    if (!this.enableContinueButton || this.shippingError) {
      this.dialog = true;
      return;
    }

    this.loadingButton = true;
    this.totalDiscountBlackFriday = 0;

    this.cartService
      .setNewCart(this.cartItems)
      .subscribe((response: { id: number }) => {
        this.loadingButton = false;
        this.router.navigate([`checkout/${response.id}`]);
      });
  }

  getPromotionImageSeal(desconto: number, tipoPromocao: string) {

    return tipoPromocao == 'porc' ? `${environment.amazon_s3}/img/promotion/percentage/Selos+de+desconto_${desconto}.png` : `${environment.amazon_s3}/img/promotion/fixed/Selos+de+desconto_${desconto}.png`;
  }

  verifyPaymentTypes() {
    this.cartItems.forEach((value) => {
      value.paymentTypes.forEach((item) => {
        if (item.end_installment == 1 && item.payment_setting.payment_category_id == this.payment_category_cartao) {
          value.is_cash_card = true;
        }
      });
    });
  }

  orderPaymentTypes() {
    this.cartItems.forEach((value) => {
      value.paymentTypes.forEach((item) => {
        if (!!item.payment_setting) {
          value.paymentTypes.sort((a, b) => a.price - b.price);

        }else {
          value.paymentTypes.sort((a, b) => a.total_amount - b.total_amount);
        }
      });
    });
  }

  setTipoPagamentoValues() {
    if(this.cartItems[0].isTipoEntrada) {
      this.isTipoEntradaTurma = true;
      this.tipoPagamento[0].label = 'Valor de entrada - ' + this.currencyPipe.transform(this.cartItems[0].valorEntrada, 'BRL', 'symbol', '1.2-2', 'pt-BR');
      this.tipoPagamento[1].label = 'Valor total - ' + this.currencyPipe.transform(this.cartItems[0].value, 'BRL', 'symbol', '1.2-2', 'pt-BR');
    }

    const paymentOptions = this.cartService.getPaymentOptionsFromCart();

    if (!paymentOptions.full_payment)
        this.tipoPagamentoSelecionado = 'entrada';
  }

  updatePaymentValueType() {
    if (this.tipoPagamentoSelecionado == 'total')
      this.cartService.updateFullPaymentOptionToCart(true);
    else
      this.cartService.updateFullPaymentOptionToCart(false);

  }
}
