import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

import { SelectItem } from "primeng/api";

import { CoreService } from 'src/app/core/core.service';
import { Unit } from 'src/app/interfaces/unit';
import { SeoService } from 'src/app/services/seo.service';
import { Constants } from 'src/app/shared/constants.service';

@Component({
  selector: "cm-units",
  templateUrl: "./units.component.html",
  styleUrls: ["./units.component.scss"]
})
export class UnitsComponent implements OnInit {
  units: Unit[] = [];
  filteredUnitList: any = [];
  modalTitle: string;
  currentUnit: Unit = new Unit;
  banner: string;
  isTerzius:boolean;
  dialog = false;
  cm_select_city: SelectItem[];
  lat: number;
  lng: number;
  zoom: number = 15;

  constructor(
    private _coreService: CoreService,
    private _activatedRoute: ActivatedRoute,
    private _seoService: SeoService) {}

  ngOnInit() {
    this._getUnits();
    this._getBanner();
    this.isTerzius = Constants.isTerzius;
    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });
  }

  // ----- PUBLIC METHODS
  selectUnitData(e: MouseEvent, selectedUnit: any): void {
    this.currentUnit = this.units.find((unit: any) => {
      if(unit.id == selectedUnit.id){
        this.lat = selectedUnit.lat;
        this.lng = selectedUnit.long;
        this.modalTitle = selectedUnit.cidade;
        return selectedUnit;
      }
    });
    this.dialog = true;
  }

  closeDialog(): void {
    this.modalTitle = "";
    this.dialog = false;
  }

  // ----- PRIVATE METHODS
  private _getUnits(): void {
    this._coreService.getUnits().subscribe((response: any) => {
      this.units = response;
      this.filteredUnitList = response.filter(unit => {
        
        if (unit.cidade === 'Belo Horizonte, MG') {
          unit.cidade = 'Belo Horizonte, MG - Matriz';
          unit.order = 1;
          return unit;
        }
        if (unit.cidade === 'Goiânia, GO') {
          unit.order = 2;
          return unit;
        }
        if (unit.cidade === 'Vitória, ES') {
          unit.order = 3;
          return unit;
        }        
        if (unit.cidade === 'Pelotas, RS') {
          unit.order = 4;
          return unit;
        }
      });
    this.filteredUnitList = this.filteredUnitList.sort(this._orderCities);
    })
  }

  private _getBanner(): void {
    const params = {page: 'unidades'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      this.banner = response.image_url;
    })
  }

  private _orderCities(unit1: any, unit2: any) {
    const order1 = unit1.order;
    const order2 = unit2.order;
  
    return order1 - order2;
  }
  
}
